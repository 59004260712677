import ColumnNames from '../enums/ColumnNames'

export const csvHeaderStringMapper = {
  [ColumnNames.FIRST_NAME]: {
    validExpressions: ['vorname', 'rufname', 'first name', 'firstname', 'given name', 'givenname'],
    required: true
  },
  [ColumnNames.LAST_NAME]: {
    validExpressions: ['nachname', 'name', 'familienname', 'last name', 'lastname'],
    required: true
  },
  [ColumnNames.EMAIL]: {
    validExpressions: ['email', 'e-mail', 'mail', 'e-mail-adresse', 'email-adresse', 'emailadresse', 'e-mail adresse'],
    required: true
  },
  [ColumnNames.PHONE]: {
    validExpressions: ['telefon', 'telephon', 'tel', 'telephone', 'phone', 'telefonnummer', 'festnetz', 'handy', 'mobile'],
    required: false
  }
}
