
<template>
  <div class="container">
    <h3>
      {{ $t('column-mapper.title') }}
    </h3>
    <p class="mb-2">
      {{ $t('column-mapper.subtitle') }}
    </p>
    <div class="content-container d-flex justify-center flex-grow-1">
      <div class="mt-4 centered-container d-flex flex-column">
        <v-row class="mt-1 mb-1 px-8">
          <v-col>
            <span><b>{{ $t('column-mapper.column-headers.app', { platformName: readablePlatformName }) }}</b></span>
          </v-col>
          <v-col><span><b>{{ $t('column-mapper.column-headers.custom') }}</b></span></v-col>
          <v-col class="pl-10">
            <span><b>{{ $t('column-mapper.column-headers.preview') }}</b></span>
          </v-col>
        </v-row>

        <v-alert
          v-if="hasDuplicateMapping"
          type="error"
          class="px-2 ma-0"
        >
          {{ $t('alerts.column-mapper.duplicate-mapping', { readableErrorFields: readableErrorFields }) }}
        </v-alert>
        <v-alert
          v-if="hasMissingRequiredMapping"
          type="error"
          class="px-2 ma-0 mt-2"
        >
          {{ $t('alerts.column-mapper.missing-mapping') }}
        </v-alert>

        <div class="mapper-rows flex-grow-1">
          <div class="mt-4">
            <v-row
              v-for="(col,index) in mappedColumns"
              :key="index"
              class="d-flex align-center justify-center px-8"
            >
              <v-col>
                <b>{{ readableColumnName(col.targetCol) }}</b>
              </v-col>

              <v-col class="d-flex align-center select-col">
                <v-select
                  v-model="col.mappedCol"
                  class="select-col"
                  :items="individualSelectData(col.targetCol)"
                  item-disabled="disable"
                  item-text="name"
                  outlined
                  hide-details
                  dense
                  :error="isDuplicate(col.mappedCol)"
                  @change="handleChange"
                />
              </v-col>

              <v-col class="pl-10 preview-col">
                {{ col.mappedCol != -1 ? getPreviewForCol(col.mappedCol) : '' }}
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </div>
    <div
      class="justify-end d-flex align-end mt-4"
    >
      <v-btn
        text
        @click="handlePrevStep"
      >
        {{ $t('buttons.column-mapper.back') }}
      </v-btn>
      <v-btn
        text
        color="primary"
        type="button"
        :disabled="!isValidMapping"
        @click="submitMapping"
      >
        {{ $t('buttons.column-mapper.next') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { ReadableColumnNames } from '../enums/ColumnNames'
import currencyMixin from '@/mixins/currency'
import brandingMixin from '@/mixins/branding'

const REQUIRED_DATA = ['EMAIL']
const NO_IMPORT_VALUE = 'Nicht importieren'

export default {
  mixins: [currencyMixin, brandingMixin],

  props: {
    targetColumns: {
      type: Array,
      required: true
    },
    availableColumns: {
      type: Array,
      required: true
    },
    exampleDataset: {
      type: Object,
      required: true
    },
    initialMappedColumnNames: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      value: '',
      mappedColumns: [],
      items: [],
      duplicateFields: {}
    }
  },
  computed: {
    isValidMapping () {
      return !this.hasMissingRequiredMapping && !this.hasDuplicateMapping
    },
    hasDuplicateMapping () {
      return Object.keys(this.duplicateFields).length > 0
    },
    hasMissingRequiredMapping () {
      return this.mappedColumns.some(mapping =>
        (REQUIRED_DATA.indexOf(mapping.targetCol) !== -1 && (!mapping.mappedCol || mapping.mappedCol === NO_IMPORT_VALUE))
      )
    },
    readableErrorFields () {
      return Object.keys(this.duplicateFields).join(',')
    }
  },
  watch: {
    initialMappedColumnNames: {
      deep: true,
      handler () {
        this.mapInitialData()
      }
    }
  },
  created () {
    this.mapInitialData()
  },
  methods: {
    handleChange () {
      this.findDuplicates()
    },
    isDuplicate (col) {
      return Object.keys(this.duplicateFields).indexOf(col) !== -1
    },
    findDuplicates () {
      this.duplicateFields = {}
      const alreadyMapped = new Set()
      for (const col of this.mappedColumns) {
        if (col?.mappedCol !== NO_IMPORT_VALUE && alreadyMapped.has(col.mappedCol)) {
          this.$set(this.duplicateFields, col.mappedCol, '')
        }
        alreadyMapped.add(col.mappedCol)
      }
    },
    readableColumnName (target) {
      const requiredSign = REQUIRED_DATA.indexOf(target) !== -1 ? '*' : ''
      return `${this.$t(ReadableColumnNames[target])} ${requiredSign}`
    },
    mapInitialData () {
      this.resetData()
      Object.keys(this.initialMappedColumnNames).forEach(key => {
        const mappedCol = this.initialMappedColumnNames[key] === -1 ? NO_IMPORT_VALUE : this.availableColumns[this.initialMappedColumnNames[key]]
        this.mappedColumns.push({ targetCol: key, mappedCol: mappedCol })
      })
      this.items = [NO_IMPORT_VALUE, ...this.availableColumns].map(name => ({ name, disable: false }))
    },
    individualSelectData (target) {
      if (REQUIRED_DATA.indexOf(target) !== -1) {
        const cloneItems = JSON.parse(JSON.stringify(this.items))
        cloneItems[0].disable = true
        return cloneItems
      }
      return this.items
    },
    resetData () {
      this.value = ''
      this.mappedColumns = []
      this.items = []
      this.duplicateFields = {}
    },
    handlePrevStep () {
      this.resetData()
      this.$emit('prevStep')
    },
    getPreviewForCol (columnName) {
      if (!this.exampleDataset[columnName]) return '____'
      if (columnName === 'kaufpreis') return this.formatPrice(this.exampleDataset[columnName])
      return this.exampleDataset[columnName]
    },
    submitMapping () {
      this.$emit('mapped', { mappedColumns: this.cleanColumns(this.mappedColumns), userColumnMapping: this.reformColumns() })
      this.resetData()
    },
    cleanColumns (mappedCol) {
      return mappedCol.filter(col => col.mappedCol !== NO_IMPORT_VALUE)
    },
    reformColumns () {
      const updatedColumns = {}
      Object.keys(this.initialMappedColumnNames).forEach(key => {
        updatedColumns[key] = this.availableColumns.indexOf(this.mappedColumns.find(col => col.targetCol === key).mappedCol)
      })
      return updatedColumns
    }
  }
}
</script>

<style scoped>
.container{
  height: 100%;
  display: flex;
  flex-direction: column;
}

.content-container {
  width: 100%;
  min-height:100px;
  overflow: hidden;
}

.centered-container{
  border: 1px solid #eeeeee;
  padding: 8px;
  border-radius: 10px;
}
.mapper-rows{
  overflow-y: scroll;
  overflow-x: hidden;
}

.select-col{
  min-width: 200px;
}

.preview-col{
  overflow-x: scroll;
}
</style>
