<template>
  <v-data-table
    v-if="validatedFileImportData"
    :headers="headers"
    :items="validatedFileImportData.entries"
    :options.sync="options"
    :loading="$apollo.queries.validatedFileImportData.loading"
    class="table elevation-1"
    :items-per-page="50"
    :server-items-length="validatedFileImportData.total"
    :footer-props="{
      disableItemsPerPage: true,
      'items-per-page-text': $t('leads.table.items-per-page'),
    }"
  >
    <template
      v-slot:item="{ item }"
    >
      <tr>
        <td
          v-for="(key, index) in columnNames"
          :key="index"
          class="text-left item"
          :class="{'white--text red':item.invalidColumns && item.invalidColumns.includes(key)}"
        >
          {{ getReadableData(key, item) }}
        </td>
      </tr>
    </template>
    <template v-slot:footer.page-text="items">
      {{ items.pageStart }} - {{ items.pageStop }} {{ $t('leads.table.of') }} {{ items.itemsLength }}
    </template>
  </v-data-table>
</template>

<script>
import GET_VALIDATED_FILE_IMPORT_DATA from '../queries/GetValidatedFileImportData.gql'
import ColumnNames, { ReadableColumnNames } from '../enums/ColumnNames'
import currencyMixin from '@/mixins/currency'

export default {
  mixins: [currencyMixin],

  props: {
    columnNames: {
      type: Array,
      required: true
    },
    dataType: {
      type: String,
      default: 'SUCCESS'
    },
    importId: {
      type: String,
      required: true
    },
    totalCount: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      page: 1,
      options: {}
    }
  },
  computed: {
    headers () {
      return this.columnNames.map(columnName => ({ text: this.$t(ReadableColumnNames[columnName]), value: columnName, sortable: false }))
    }
  },
  watch: {
    options: {
      handler () {
        const { page } = this.options
        this.page = page
        this.$apollo.queries.validatedFileImportData.refetch()
      },
      deep: true
    }
  },
  methods: {
    getReadableData (key, item) {
      if (!item.data[key]) return ''
      if (key === ColumnNames.PROPERTY_PRICE && this.dataType === 'SUCCESS') return this.formatPrice(item.data[key])
      return item.data[key]
    }
  },
  apollo: {
    validatedFileImportData: {
      query: GET_VALIDATED_FILE_IMPORT_DATA,
      variables () {
        return {
          input: {
            importId: this.importId,
            dataType: this.dataType,
            page: this.page,
            itemsPerPage: 50
          }
        }
      },
      skip () {
        return !this.importId
      }
    }
  }
}
</script>

<style>
.item {
  min-width: 200px
}

@media (min-width: 1025px) {
  .table {
    width: 80vw;
  }
}

</style>
