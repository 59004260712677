
<template>
  <div class="container">
    <h3>
      {{ $t('preview.title') }}
    </h3>
    <p class="mb-2">
      {{ $t('preview.subtitle') }}
    </p>

    <div class="mt-4 content-container flex-grow-1">
      <v-tabs
        v-if="dataCounts"
        v-model="tab"
      >
        <v-tab>{{ $t('preview.tabs.0') }} ({{ dataCounts.validRowsCount }})</v-tab>
        <v-tab>{{ $t('preview.tabs.1') }} ({{ dataCounts.invalidRowsCount }})</v-tab>
      </v-tabs>

      <v-tabs-items
        v-model="tab"
        class="rows"
      >
        <v-tab-item>
          <PreviewTableFull
            :import-id="importId"
            :column-names="columnNames"
            :total-count="dataCounts.validRowsCount + dataCounts.invalidRowsCount "
            data-type="SUCCESS"
          />
        </v-tab-item>
        <v-tab-item>
          <PreviewTableFull
            :import-id="importId"
            :column-names="columnNames"
            :total-count="dataCounts.validRowsCount + dataCounts.invalidRowsCount "
            data-type="ERROR"
          />
        </v-tab-item>
      </v-tabs-items>
    </div>
    <div class="justify-center d-flex align-end mt-4">
      <v-checkbox
        v-model="permissionGranted"
        class="mt-0 subtitle-2"
      >
        <template v-slot:label>
          <span class="subtitle-2 font-weight-regular black--text">{{ $t('labels.data-processing-granted') }}</span>
        </template>
      </v-checkbox>
    </div>
    <div class="justify-end d-flex align-end mt-4">
      <v-btn
        text
        @click="handlePrevStep"
      >
        {{ $t('labels.back') }}
      </v-btn>
      <v-tooltip
        top
        :disabled="permissionGranted && !!dataCounts.validRowsCount"
      >
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <v-btn
              text
              color="primary"
              type="button"
              :disabled="submitting || !dataCounts.validRowsCount || !permissionGranted"
              @click="submitUpload"
            >
              {{ $t('buttons.preview.cta', { amount: dataCounts.validRowsCount }) }}
            </v-btn>
          </div>
        </template>
        <span v-if="!dataCounts.validRowsCount">{{ $t('alerts.preview.no-data') }}</span>
        <span v-else-if="!permissionGranted">{{ $t('alerts.preview.no-permission') }}</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import PreviewTableFull from './PreviewTableFull.vue'

export default {
  components: { PreviewTableFull },
  props: {
    importId: {
      type: String,
      required: true
    },
    dataCounts: {
      type: Object,
      default: () => {}
    },
    columnNames: {
      type: Array,
      required: true
    },
    submitting: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      tab: null,
      permissionGranted: false
    }
  },
  methods: {
    handlePrevStep () {
      this.$emit('prevStep')
    },
    submitUpload () {
      this.$emit('import')
    }
  }
}
</script>

<style scoped>
.container{
  height: 100%;
  display: flex;
  flex-direction: column;
}

.content-container {
  width: 100%;
  min-height: 100px;
  border: 1px solid #eeeeee;
  border-radius: 10px;
  padding-bottom:0px;
  overflow: scroll;
}

</style>
