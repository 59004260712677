
<template>
  <div
    class="container"
  >
    <div
      class="content-container flex-grow-1"
      :style="{backgroundColor: $vuetify.theme.themes.light.primary}"
    >
      <div class="d-flex flex-column status-message-content">
        <h2 class="mb-4">
          {{ $t('file-upload.submit.title') }}
        </h2>
        <h3>
          {{ $t('file-upload.submit.body-texts.0') }}
        </h3>
        <h3 class="mt-4">
          {{ $t('file-upload.submit.body-texts.1') }}
        </h3>

        <v-progress-linear
          color="white"
          class="mt-4"
          :value="intervalValue"
        />
        <p>
          {{ $t('file-upload.submit.body-texts.2') }}
        </p>
      </div>
      <div
        class="justify-end d-flex align-end"
      >
        <v-btn
          text
          color="white"
          @click="cancel"
        >
          {{ $t('buttons.file-upload.submit.cta') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      interval: null,
      intervalValue: 100
    }
  },
  created () {
    this.displayInfo()
  },
  methods: {
    cancel () {
      this.$emit('cancel')
    },
    displayInfo () {
      this.interval = setInterval(() => {
        if (this.intervalValue === 0) {
          clearInterval(this.interval)
          this.$emit('cancel')
          return
        }
        this.intervalValue -= 1
      }, 100)
    }
  }
}
</script>

<style scoped>
.container{
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #eeeeee;
  background-color: var(--primary-color);
  border-radius: 10px;
  padding: 8px;
  text-align: center;
  color: white;
  min-height:100px;
  height: 100%;
  width: 100%;
}

.status-message-content{
  width: 400px;
}
</style>
