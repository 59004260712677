
const ColumnNames = Object.freeze({
  FIRST_NAME: 'FIRST_NAME',
  LAST_NAME: 'LAST_NAME',
  EMAIL: 'EMAIL',
  PHONE: 'PHONE'
})

export const ReadableColumnNames = Object.freeze({
  FIRST_NAME: 'labels.first-name',
  LAST_NAME: 'labels.last-name',
  EMAIL: 'labels.email',
  PHONE: 'labels.phone-number'
})

export default ColumnNames
