<template>
  <div>
    <v-dialog
      v-model="showImportDialog"
      width="unset"
      persistent
      scrollable
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          v-bind="attrs"
          v-on="on"
        >
          <slot name="activator" />
        </div>
      </template>
      <v-card style="height:90vh">
        <v-card-title
          class="pa-0"
        >
          <v-toolbar
            color="primary"
            dark
            flat
          >
            <v-toolbar-title>{{ $t('labels.import-contacts') }}</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-btn
                icon
                dark
                @click="handleCancel"
              >
                <v-icon large>
                  mdi-close
                </v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>

        <v-card-text
          class="d-flex align-center pa-0"
        >
          <v-container class="stepper-container">
            <v-stepper
              v-model="step"
              non-linear
              alt-labels
              elevation="0"
              class="stepper"
            >
              <v-stepper-header class="flat">
                <template
                  v-for="({title}, index) in steps"
                >
                  <v-stepper-step
                    :key="title"
                    :complete="step>index+1"
                    class="stepper-head"
                    edit-icon="$complete"
                    :step="index + 1"
                    @click="goToStep(index)"
                  >
                    {{ title }}
                  </v-stepper-step>
                  <v-divider
                    v-if="index !== steps.length - 1"
                    :key="index"
                  />
                </template>
              </v-stepper-header>

              <v-stepper-items class="justify-center d-flex stepper-items">
                <v-stepper-content
                  class="stepper-content"
                  step="1"
                >
                  <file-upload
                    class="import-step-container"
                    :reset="fileUpload.shouldResetUpload"
                    @upload="handleUpload"
                    @upload-error="fileUpload.hasUploadError=true"
                    @reset-finished="handleUploadReset"
                  />
                </v-stepper-content>

                <v-stepper-content
                  class="stepper-content"
                  step="2"
                >
                  <upload-loader
                    v-if="isValidating"
                    :is-done="isValidatingDone"
                    class="import-step-container"
                  />

                  <column-mapper
                    v-if="!isValidating && importColumnNames"
                    class="import-step-container"
                    v-bind="mapperProps"
                    :reset="fileUpload.shouldResetUpload"
                    @mapped="validateFileData"
                    @prevStep="handlePrevStep"
                  />
                </v-stepper-content>

                <v-stepper-content
                  class="stepper-content"
                  step="3"
                >
                  <import-error
                    v-if="importError"
                    class="import-step-container"
                    :import-error="importError"
                  />
                  <preview
                    v-if="!importError && dataCounts"
                    class="import-step-container"
                    v-bind="uploadProps"
                    :submitting="submitting"
                    @cancel="handleCancel"
                    @import="handleImport"
                    @prevStep="handlePrevStep"
                  />
                </v-stepper-content>

                <v-stepper-content
                  class="stepper-content"
                  step="4"
                >
                  <submit
                    v-if="step===4"
                    class="import-step-container"
                    @cancel="handleCancel"
                  />
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import brandingMixin from '@/mixins/branding'
import ColumnMapper from './ImportComponents/ColumnMapper'
import FileUpload from './ImportComponents/FileUpload'
import Preview from './ImportComponents/Preview'
import Submit from './ImportComponents/Submit'
import ImportError from './ImportComponents/ImportError'
import UploadLoader from './ImportComponents/UploadLoader'
import ColumnNames from './enums/ColumnNames'
import { csvHeaderStringMapper } from './helper'
import VALIDATE_FILE_IMPORT_DATA from './queries/ValidateFileImportData.gql'
import START_IMPORT_DATA from './queries/StartImportData.gql'

export default {
  components: {
    ColumnMapper,
    FileUpload,
    Preview,
    Submit,
    ImportError,
    UploadLoader
  },
  mixins: [brandingMixin],
  props: {
    refetchParent: {
      type: Function,
      default: () => {}
    },
    showImportDialog: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      submitting: false,
      step: 1,
      file: null,
      csvPreviewColumns: null,
      resetImportData: false,
      userColumnMapping: null,
      cleanColumns: null,
      cleanDataset: null,
      importColumnNames: null,
      exampleDataset: null,
      importData: null,
      fileName: '',
      dataCounts: null,
      isValidating: false,
      isValidatingDone: false,
      importError: '',
      importId: null,
      fileUpload: {
        shouldResetUpload: false,
        hasUploadError: false
      },
      columnMapper: {
        handlePrevStep: false,
        isValidMapping: false,
        shouldResetMapper: false
      }
    }
  },
  computed: {
    steps () {
      const steps = [
        { title: this.$t('labels.select-file') },
        { title: this.$t('labels.assignment') },
        { title: this.$t('labels.preview') },
        { title: this.$t('labels.import-data') }
      ]
      return steps.map((step, index) => ({ ...step, step: index + 1 }))
    },

    mapperProps () {
      const initialMappedColumnNames = this.userColumnMapping || this.mapInitialColumnNames(Object.keys(ColumnNames), this.importColumnNames)

      return {
        targetColumns: Object.keys(ColumnNames),
        availableColumns: this.importColumnNames,
        exampleDataset: this.exampleDataset,
        initialMappedColumnNames,
        reset: this.columnMapper.shouldResetMapper
      }
    },

    uploadProps () {
      return {
        dataCounts: this.dataCounts,
        importId: this.importId,
        columnNames: Object.keys(ColumnNames)
      }
    }

  },
  methods: {
    goToStep (index) {
      if (index + 1 < this.step) {
        this.step = index + 1
      }
    },
    handlePrevStep () {
      this.step = this.step - 1
    },
    handleNextStep () {
      this.step = this.step + 1
    },

    handleCancel () {
      this.step = 1
      this.isValidating = false
      this.isValidatingDone = false
      this.importColumnNames = null
      this.exampleDataset = null
      this.importData = null
      this.$emit('cancel')
    },
    handleUpload ({ fileName, columnNames, exampleDataset, data }) {
      this.fileName = fileName
      this.importColumnNames = columnNames
      this.exampleDataset = exampleDataset
      this.importData = data
      this.userColumnMapping = null
      this.handleNextStep()
    },
    handleUploadReset () {
      this.fileUpload.shouldResetUploadData = false
      this.fileUpload.hasUploadError = false
    },
    mapInitialColumnNames (targetColumnNames, columnNames) {
      const validatedColumnNames = {}

      targetColumnNames.forEach((expectedKey) => {
        validatedColumnNames[expectedKey] = -1
        columnNames.forEach((columnName, i) => {
          if (
            csvHeaderStringMapper[expectedKey].validExpressions.indexOf(columnName.replace(/"/g, '').toLowerCase()) !== -1
          ) {
            validatedColumnNames[expectedKey] = i
          }
        })
      })
      return validatedColumnNames
    },
    async validateFileData ({ mappedColumns, userColumnMapping }) {
      this.isValidating = true
      this.userColumnMapping = userColumnMapping

      const { data } = await this.$apollo.mutate({
        mutation: VALIDATE_FILE_IMPORT_DATA,
        variables: {
          input: {
            columnNameMapping: mappedColumns,
            rawData: this.importData,
            fileName: this.fileName
          }
        }
      })
      this.isValidatingDone = true

      if (data?.validateFileImportData) {
        this.importId = data.validateFileImportData.importId
        this.dataCounts = { validRowsCount: data.validateFileImportData.validRowsCount, invalidRowsCount: data.validateFileImportData.invalidRowsCount }
        setTimeout(() => {
          this.isValidating = false
          this.isValidatingDone = false
          this.handleNextStep()
        }, 1500)
      } else {
        throw new Error('Missing data')
      }
    },
    async handleImport () {
      this.submitting = true
      try {
        const { data } = await this.$apollo.mutate({
          mutation: START_IMPORT_DATA,
          variables: {
            importId: this.importId
          }
        })

        if (!data) {
          this.importError = 'Problem beim Hochladen.'
        } else if (data.startFileImport?.error) {
          if (data.startFileImport.error === 'NOT_FOUND') {
            this.importError = 'Es wurde keine korrekten Daten erkannt.'
          } else if (data.startFileImport.error === 'NO_VALID_DATA') {
            this.importError = 'Mindestens ein valider Datensatz wird zum Importieren benötigt.'
          } else if (data.startFileImport.error === 'IMPORT_PROCESS_ALREADY_STARTED') {
            this.importError = 'Der Import Prozess wurde bereits gestartet.'
          } else if (data.startFileImport.error) {
            this.importError = 'Problem beim Hochladen.'
          }
        } else {
          this.refetchParent()
          this.handleNextStep()
        }
      } finally {
        this.submitting = false
      }
    }

  }
}
</script>

<style>
/* No scope to overwrite vuetify class */
.stepper-content > .v-stepper__wrapper  {
  height: 100% !important;
}
</style>

<style scoped>
.stepper-container{
  max-width: 100% !important;
  height: 100%;
  padding: 0px !important;
}

.stepper{
  height:100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.stepper-head:hover{
  cursor: pointer;
}

.stepper-items{
  height:100%
}

.stepper-content{
  padding-top:0px !important;
  padding-bottom:0px !important;
  height: 100%;
}

.import-step-container{
  min-width: 900px;
}

@media (max-width: 1000px) {
  .import-step-container {
    min-width: 600px;
  }
}

.flat{
  box-shadow: none;
  -webkit-box-shadow: none;
}
</style>
